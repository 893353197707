/*extra styling if needed for pages*/

.main{
    background-color: lightgray;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.description p{
    font-size: 20px;
}

/*extra styling Search Page*/

button{
    color:black;
}

button{
    color:whiite;
}

/*you tube video boxes*/

.youtube-container{
    display:flex;
    flex-direction: column;
    background:none;
}

.search-container{

    display:flex;
    flex-direction: row;
    padding: 20px;
    align-content: center;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
    column-gap: 50px;
    background: none;
}

.videos{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
    row-gap: 40px;
}

.video-container{
    justify-content: center;
    align-content: center;
    display:flex;
    flex-direction: column;
    flex-basis: 25%;
    box-shadow: 0px 0px 25px -1px black;
    padding: 20px;
    border-radius: 15px;
    padding:30px;
    margin:20px;
    background-color: rgba(255,255,255,0.1);
    backdrop-filter: blur(0.8rem);
}


/*Recipes Styling*/

.recipe-page{
    display:flex;
    flex-direction: column;
    background:none;

}

.recipe-search-form{
    display:flex;
    flex-direction: row;
    padding: 20px;
    align-content: center;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
    column-gap: 50px;
    background: none;
}

.recipe-btn, .yt-btn{
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: 5px;
    border: 2px solid white;
}

.recipe-btn:hover, .yt-btn:hover{
   background-color: white;
   color: navy;
    transition: all 0.2s ease-out;
}


.search-info-text{
    color: white;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    margin-top:20px;
    margin-bottom:-30px;
    text-shadow: 1px 1px 0 #000;
}

.ytsearch-info-text{
    color: white;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    margin-top:20px;
    margin-bottom:0px;
    text-shadow: 1px 1px 0 #000;
}

.search-text-box{
    border: 4px solid white;
    font-size: 20px;
}

label{
  
    font-size: 20px;
    color:white;
}

.recipes{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    
}

.recipe-container{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-basis: 25%;
    padding: 20px;
    border-radius: 15px;
    padding:30px;
    margin:20px;
    box-shadow: 0px 0px 25px -1px black;
    background-color: rgba(255,255,255,0.1);
    backdrop-filter: blur(0.8rem);
}

.recipe-title{
    padding-bottom: 10px;
    padding-top: -5px;
    margin:0;
    font-size: 18px;
    word-wrap: break-word;
    text-align: center;
    color: white;
    text-shadow: 1px 1px 0 #000;
}


.recipe-img-deets{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.rec-link{
    margin:0;
    font-size: 22px;
    word-wrap: break-word;
    text-align: center;
    color:navy;
    font-weight: bold;
    padding-top: 20px;
}

.rec-link:hover{
    color: white;
}

.rec-link:link{
    text-decoration: none;
}


.recipe-img{
   border-radius: 10px;
   max-width: 100%;
   overflow: hidden;
}

.recipedeets{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.search-bgimg{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}


.description-page{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 20px;
}

.description-head{
    padding-bottom: 10px;
    padding-top: 10px;
    margin:0;
    font-size: 35px;
    word-wrap: break-word;
    text-align: center;
    color: white;
    text-shadow: 1px 1px 0 #000;
}


.description-info{
    line-height: 2em;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.1);
    backdrop-filter: blur(0.8rem);
    color: white;
    font-weight: bold;
    text-align: center;
    width:60%;
    box-shadow: 0px 0px 25px -1px black;
}

ul{
    list-style-type: none;
}

.search-img{
    scale:(1,0.8);
    border-radius: 10px;
    box-shadow: 0px 0px 25px -1px black;
    background-color: rgba(255,255,255,0.1);
    backdrop-filter: blur(0.8rem);
    margin:20px;
}


.yt-image{
    scale:(2,1);
    border-radius: 10px;
    box-shadow: 0px 0px 25px -1px black;
    background-color: rgba(255,255,255,0.1);
    backdrop-filter: blur(0.8rem);
    margin:20px;
}


/* change flex direction of search containers for mobile*/

@media screen and (max-width: 960px) {

    .recipe-search-form{
        display:flex;
        flex-direction: column;
        padding: 20px;
        row-gap: 30px;
        column-gap: 50px;
    }

    .search-container{
        display:flex;
        flex-direction: column;
        padding: 20px;
        row-gap: 30px;
        column-gap: 50px;
       
    }

    .recipe-btn, .yt-btn{
        color: white;
        font-weight: bold;
        font-size: 18px;
        margin-top: 5px;
        border: 2px solid white;
        align-content: center;
    }
    


}

