/* Nav Bar menu inc media Query*/

/*Header*/
.navbar {
  background: #330099;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.welcome {
  color: #fff;
  display:flex;
  align-items: flex-start;
  text-decoration: none;
  font-size: 2rem;
  margin:0 120px;
  text-align: left;
  
}

.jt a {
  color: #fff;
  text-decoration: none;
  font-size: 22px;
  border: 2px solid white;
  border-radius: 15px;
  text-align: right;
  margin-top: 17px;
}


ul{
  align-content: center;
  justify-content: center;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  width: 60vw;
  
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-item li a{
  color: #fff;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin-left:2rem;
  margin-right:2rem;
  height: 100%;
}

.jt .nav-links{
  height: fit-content;
  align-items: baseline;

}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  font-size: larger;
  font-weight: bold;
  transition: all 0.2s ease-out;
  color: aliceblue;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

/*mobile nav*/
@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    transform:translateX(-30%);
    transition:transform 350ms ease-out;
  }

  .nav-menu.active {
    background-color: rgba(51,0,153,0.7);
    backdrop-filter: blur(1rem);
    left: 0;
    transition: all 0.5s ease;
    z-index: 100;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display:flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: -200px;
    margin-left: 100px;;
  }

  .nav-links:hover {
    font-weight: bold;
    border-bottom: none;
    font-size: larger;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }


  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-login{
    padding-top:55px;
    text-align: center;
  }

  
}