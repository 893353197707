/*Styling for The home section*/

/*Background video*/
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}


.home-container {
  background:none;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  padding-top: 30px;
  padding-bottom: 80px;
}

.home-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: 100px;
}

.home-container > p {
  margin-top: 20px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}


@media screen and (max-width: 960px) {
  .home-container > h1 {
    font-size: 50px;
    margin-top: 550px;
  }
}

@media screen and (max-width: 768px) {
  .home-container > h1 {
    font-size: 40px;
    margin-top: 500px;
    margin-bottom: 0px;
    padding-bottom: 0;
  }

  .home-container > p {
    font-size: 30px;
  }

}