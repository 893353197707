/* styling for cards on home page */


/* home cards - whole section */
.home-cards {
  display: flex;
  padding: 4rem;
  flex-direction: column;
  background:none;
}
  
h1 {
  text-align: center;
}
  
.home-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  width: 90%;
  margin: 0 auto;

  padding-top: 10rem;
  padding-top: 10rem;
  background:none
}


/* Individual Cards */

.card-item {
  display: flex;
  flex: 1;
  margin: 1rem 4rem;
  background-color: rgba(255,255,255,0.1);
  backdrop-filter: blur(0.8rem);
  box-shadow: 0px 0px 25px -1px rgba(255,255,255,0.5);
  border-radius: 20px;
 
}

.card-item-container{
  position:relative;
  width:100%;
  height:auto;
  
 
}

.card-item-link {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
}


.card-item-label{
  position: absolute;
  bottom: 0px;
  left:0px;
  width:30%;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 700;
  color:white;
  background-color: rgb(51,0,153);
  box-sizing: border-box;
  text-align: center;
}

.card-item-img {
  top:5px;
  border-radius: 20px;
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

/*pic zoom on hover*/
.card-item-img:hover {
  transform: scale(1.2);
 
}

.card-item-info {
  padding-top: 20px;
  padding-left:15px;
  padding-right:15px;
  padding-bottom:20px;
  text-align:center; 
}

.card-item-text {
  color: white;
  text-shadow: 1px 1px 0 #000;
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
  text-align:center; 
}

@media only screen and (min-width: 1024px) {
  .home-cards-container {
    flex-direction: row;
    padding-top: 1.5rem;
  }
}

@media only screen and (max-width: 1024px) {
  .card-item {
    margin-bottom: 2rem;
  
  }

  .home-cards-container {
    flex-direction: column;
    padding-top: 1rem;
    background: none;
  }


  
}