/*Central styling app.js*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:sans-serif;
}

button{
  
  padding: 8px 20px;
  border-radius: 5px;
  outline: none;
  border: 1px solid white;
  cursor: pointer;
  background: none;
  color: white;
  margin-top: 22px;
  margin-left:20px;
}


.home,
.search,
.watch,
.description {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: lightgray;

}

.watch {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 40px;
}
/* update these*/
.search {
  color: black;
  font-size: 20px;
}

.description{
  display:flex;
  flex-direction: column;
  color: black;
  font-size: 40px;
}

.description-body{
  color: black;
  font-size: 20px;
  padding:30px 200px;
}

.description-head{
  color: black;
  font-size: 30px;
}

.check{

}

@media screen and (max-width: 960px) {
  button{
    padding-top: 50px;
    border:none;
    font-size: 20px;
  
  }


}